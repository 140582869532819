/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("roboto-v20-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("roboto-v20-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("roboto-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("roboto-v20-latin-regular.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("roboto-v20-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("roboto-v20-latin-500.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("roboto-v20-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("roboto-v20-latin-700.svg#Roboto")
      format("svg"); /* Legacy iOS */
}

/* roboto-slab-500 - latin */
@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("roboto-slab-v12-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("roboto-slab-v12-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-slab-v12-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-slab-v12-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("roboto-slab-v12-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("roboto-slab-v12-latin-500.svg#RobotoSlab")
      format("svg"); /* Legacy iOS */
}
