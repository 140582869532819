.swiper-pagination {
  height: 20px;
  padding: 6px 0;
  bottom: 18px !important;
}

.swiper-pagination-bullet {
  box-shadow: 0 0 3px 4px rgba(0, 0, 0, 0.5);
  background-color: #ffc107 !important;
}
